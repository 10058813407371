import clsx from 'clsx';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import Image from 'next/image';
import type { FunctionComponent } from 'react';

interface TextProps {
  html: string;
  className?: string;
}

const Prose: FunctionComponent<TextProps> = ({ html, className }) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if ('attribs' in domNode && domNode.name === 'img') {
        const { src, alt, width, height, 'data-element': dataElement } = domNode.attribs;
        
        if (!src) return null;

        let finalSrc = src;
        if (src.includes('/media/wysiwyg/')) {
          const fileName = src.split('/media/wysiwyg/').pop();
          finalSrc = `https://${process.env.BLOB_STORE}/Blogs/${fileName}`;
        }
        
        const parsedWidth = width ? parseInt(width) : 0;
        const parsedHeight = height ? parseInt(height) : 0;
        
        const finalWidth = parsedWidth > 0 ? parsedWidth : 800;
        const finalHeight = parsedHeight > 0 ? parsedHeight : 600;

        const responsiveClasses = dataElement === 'mobile_image' 
          ? 'md:hidden block' 
          : dataElement === 'desktop_image' 
          ? 'hidden md:block' 
          : '';

        return (
          <Image
            src={finalSrc}
            alt={alt || ''}
            width={finalWidth}
            height={finalHeight}
            className={`max-w-[600px] w-auto h-auto ${responsiveClasses}`}
          />
        );
      }
    }
  };

  return (
    <div
      className={clsx(
        'prose mx-auto text-[18px] !font-light !leading-normal	max-w-6xl text-[#6e6e6e] prose-headings:mt-8 prose-headings:font-semibold prose-headings:tracking-wide prose-headings:text-black prose-h1:text-5xl prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-h5:text-xl prose-h6:text-lg prose-a:text-black prose-a:underline hover:prose-a:text-neutral-300 prose-strong:text-black prose-ol:mt-8 prose-ol:list-decimal prose-ol:pl-6 prose-ul:mt-8 prose-ul:list-disc prose-ul:pl-6 dark:text-white dark:prose-headings:text-white dark:prose-a:text-white dark:prose-strong:text-white',
        className
      )}
    >
      {parse(html, options)}
    </div>
  );
};

export default Prose;
